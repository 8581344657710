import { defineStore } from 'pinia'

export const useNotificationStore = defineStore('useNotificationStore', {
  state: () => {
    return {
      notifications: []
    }
  },
  actions: {
    setError(errorObj) {
      if (!errorObj.timeout || errorObj.timeout > 5000) {
        errorObj.timeout = 5000
      }
      errorObj.model = true
      errorObj.type = 'error'
      this.notifications.push(errorObj)
      setTimeout(() => {
        this.notifications.shift()
      }, errorObj.timeout)
    }
  },
  getters: {}
})